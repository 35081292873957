import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "../scss/main.scss"
import SubPage from "../layouts/Subpage"
import bannerImage from '../../static/images/slide-1.jpg';
import ReferencesContent from "../content/References"
export default function IndexPage() {
  const intl = useIntl()
  return <SubPage bannerImage={bannerImage}>
    <ReferencesContent lang={intl.locale} />
  </SubPage>
}
