import React from "react"
import DocumentsList from "../../components/DocumentsList"


const ReferencesDE = ({referenceFiles}) => <>
    <h2>Referenzen / Zertifikate</h2>
    {/*<p class="bigger-text text-center"> Test Seit der Unternehmensgründung legen wir einen großen Wert auf volle Kundenzufriedenheit.</p>*/}
    <h3>Referenzen</h3>
    <DocumentsList list={referenceFiles} />
    {/*<h3>Zertifikate</h3>*/}
    {/*<ul class="documents-list row">*/}

    {/*<li class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">*/}
    {/*<a href="/resources/ISO9001.pdf" target="_blank">*/}
    {/*<div class="document-title-wrapper">*/}
    {/*<i class="fa fa-file-text" aria-hidden="true"></i>*/}
    {/*<h4>ISO9001</h4>*/}
    {/*</div>*/}
    {/*<span class="external-link-info"><i class="fa fa-external-link" aria-hidden="true"></i></span>*/}
    {/*</a>*/}
    {/*</li>*/}
    {/*</ul>*/}
</>

export default ReferencesDE