import React from "react"
import styles from "./styles.module.scss"
import css from "classnames"

const DocumentsList = ({ list }) => <ul className={css(styles.DocumentsList, "row")}>
  {list.map(item => <li className={css(styles.item, "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3")}>
    <a href={item.file} target="_blank" className={styles.link}>
      <div className={styles.wrapper}>
        <i className={css(styles.icon, "fa fa-commenting-o")} aria-hidden="true" />
        <h4>{item.name}</h4>
      </div>
      <span className={styles.externalLink}><i className="fa fa-external-link" aria-hidden="true" /></span>
    </a>
  </li>)}
</ul>

export default DocumentsList