import React from "react"
import DocumentsList from "../../components/DocumentsList"


const ReferencesEN = ({ referenceFiles }) => <>
  <h2>References / certyficates</h2>
  <h3>References</h3>
  <DocumentsList list={referenceFiles} />
</>

export default ReferencesEN