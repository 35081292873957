import React from "react"
import ReferencesPL from "./references.pl"
import ReferencesEN from "./references.en"
import ReferencesDE from "./references.de"
import skanska from "../../../static/files/SKANSKA.pdf"
import warbud from "../../../static/files/WARBUD.pdf"
import inmet from "../../../static/files/INMET.pdf"
import em from "../../../static/files/em.pdf"

const REFERENCE_FILES = [
  {
    name: "Skanska S.A.",
    file: skanska
  },
  {
    name: "Warbud S.A.",
    file: warbud
  },
  {
    name: "INMET Konstrukcje sp.z.o.o.",
    file: inmet
  },
  {
    name: "ENERGOMONTAŻ-PÓŁNOC BEŁCHATÓW sp.z.o.o.",
    file: em
  }
]


const ReferencesContent = ({ lang }) => {
  if (lang === "de") {
    return <ReferencesDE referenceFiles={REFERENCE_FILES} />
  } else if (lang === "en") {
    return <ReferencesEN referenceFiles={REFERENCE_FILES} />
  }
  return <ReferencesPL referenceFiles={REFERENCE_FILES} />
}

export default ReferencesContent