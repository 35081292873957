import React from "react"
import DocumentsList from "../../components/DocumentsList"


const ReferencesPL = ({ referenceFiles }) => <>
  <h2>Referencje / Certyfikaty</h2>
  <h3>Referencje</h3>
  <DocumentsList list={referenceFiles} />
</>

export default ReferencesPL